import Config from '../config/ConfigApi.json';
import {toast } from 'react-toastify';
import axios from 'axios';

//Constantes
const DashboardCentralState={
    ReloadNota:false,
    Central:[],
    Busqueda:[],
    user:[],
    ChatObjetivo:[],
    Chat:[],
    Notificacion:[]
};

//types
const STATE_DASHBOARD_CENTRAL="STATE_DASHBOARD_CENTRAL";
const INSERTAR_NOTA="INSERTAR_NOTA";
const BUSCAR_PENDIENTE="BUSCAR_PENDIENTE";
const USUARIO_RE="USUARIO_RE";
const PURGA="PURGA";
const CHAT_OBJETIVO="CHAT_OBJETIVO";
const CHAT_CONTENT="CHAT_CONTENT";
const NOTIFICACIONES="NOTIFICACIONES";

//reducer

export default function DashboardReducer(state=DashboardCentralState,action){
    switch (action.type) {
        case STATE_DASHBOARD_CENTRAL:
            return {...state, Central:action.payload}
        case INSERTAR_NOTA:
            return {...state, ReloadNota:action.payload}
        case BUSCAR_PENDIENTE:
            return {...state, Busqueda:action.payload}
        case USUARIO_RE:
            return {...state, user:action.payload}
        case CHAT_OBJETIVO:
            return {...state, ChatObjetivo:action.payload}
        case CHAT_CONTENT:
            return {...state, Chat:action.payload}
        case NOTIFICACIONES:
            return {...state, Notificacion:action.payload}
        case PURGA:
            return {...state,ReloadNota:false,Notificacion:[],Central:[],Busqueda:[],user:[],Chat:[]}
        default:
            return state
    }
}

//Acciones

export const DashboardCentral = (user) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'Dashboard/'+user;
        const response = await fetch(url).then(response=>response.json()).then(Json=>{
            dispatch({
                type:STATE_DASHBOARD_CENTRAL,
                payload: Json
            })
            dispatch(BusquedaPendiente(Json.Pendiente));
        });
        
    } catch (error) {
        console.log(error)
    }
}

export const DetectarNotificacion = (user) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'notificacion';
        const formData = new FormData();
        formData.append('id', user);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            dispatch({
                type:NOTIFICACIONES,
                payload: Json
            });
            //console.log(Json)
        });
    } catch (error) {
        console.log(error)
    }
}


export const InsertNota = (user,titulo,mensaje) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'insertnota';
        const formData = new FormData();
        formData.append('id', user)
        formData.append('titulo', titulo);
        formData.append('mensaje', mensaje);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            dispatch({
                type:INSERTAR_NOTA,
                payload: Json.validation
            });
            toast.success("Nota guardada exitosamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        });
        dispatch(DashboardCentral(user));
        
    } catch (error) {
        console.log(error)
    }
}

export const InsertPendiente = (user,titulo) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'insertpendiente';
        const formData = new FormData();
        formData.append('id', user)
        formData.append('titulo', titulo);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            dispatch({
                type:INSERTAR_NOTA,
                payload: Json.validation
            });
            toast.success("Pendiente registrado con exito ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
        dispatch(DashboardCentral(user));
        
    } catch (error) {
        console.log(error)
    }
}

export const UpdatePendiente = (idpendiente,estado,user) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'updatependiente';
        const formData = new FormData();
        formData.append('id', idpendiente)
        formData.append('estado', estado);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            dispatch({
                type:INSERTAR_NOTA,
                payload: Json.validation
            });
            toast.success("Pendiente actualizado con exito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        });
        dispatch(DashboardCentral(user));
        
    } catch (error) {
        console.log(error)
    }
}

export const BusquedaPendiente = (BusquedaPendiente) => async (dispatch,getState) =>{
    try {        
        await dispatch({
            type:BUSCAR_PENDIENTE,
            payload: BusquedaPendiente
        });
        
    } catch (error) {
        console.log(error)
    }
}

export const Uploadimg = (img,mensaje,user,nomu) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'insertsocial';
        const formData = new FormData();
        formData.append('nam', nomu);
        formData.append('user', user);
        formData.append('imgpost', img);
        formData.append('mensaje', mensaje);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        dispatch(DashboardCentral(user));
        
        toast.success("Publicado con exito!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

    } catch (error) {
        console.log(error)
    }
}

export const ChatObjetivo = (id) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'chatobjetivo';
        const formData = new FormData();
        formData.append('id', id);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()
        ).then(Json=>{
            console.log(Json);
            dispatch({
                type:CHAT_OBJETIVO,
                payload: Json
            })
        }
        );

    } catch (error) {
        console.log(error)
    }
}

export const Chat = (user,idobjetivo) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'chatview';
        const formData = new FormData();
        formData.append('user', user);
        formData.append('idobjetivo', idobjetivo);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            console.log(Json.toString());
            dispatch({
                type:CHAT_CONTENT,
                payload: Json
            })}
        );
        // dispatch(DashboardCentral(user));
    } catch (error) {
        console.log(error)
    }
}

export const ChatInsert = (user,idobjetivo,mensaje) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'chatinsert';
        const formData = new FormData();
        formData.append('user', user);
        formData.append('idobjetivo', idobjetivo);
        formData.append('mensaje', mensaje);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
            console.log(Json.toString());
        });
    } catch (error) {
        console.log(error)
    }
}



export const ComentarioPost = (titulo,user,post) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'insertcoment';
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('user', user);
        formData.append('post', post);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        dispatch(DashboardCentral(user));
        toast.success("Comentario Publicado con exito!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    } catch (error) {
        console.log(error)
    }
}


export const VerificadorYLogin = (user,contra) => async (dispatch,getState) =>{
    try {
        const url=Config.baseurl+'login';
        const formData = new FormData();
        formData.append('user', user);
        formData.append('con', contra);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(async(item)=>{
            
            if(item.validation!=false){
                dispatch({
                    type:USUARIO_RE,
                    payload: item
                });
                    console.log("aca va el item")
                    console.log(item)
                //------------------------------------------------------------------------
                var OSName="Desconocido";
                var NAName="Desconocido";
                var info=window.navigator.appVersion;
                
                if(info.indexOf("Windows") != -1){  OSName="Windows"};
                if(info.indexOf("Mac")!=-1) {OSName="MacOS"};
                if(info.indexOf("X11")!=-1){ OSName="UNIX"};
                if(info.indexOf("Linux")!=-1){ OSName="Linux"};

                if (navigator.userAgent.search("MSIE") >= 0) {
                    NAName="IE";
                    }
                    else if (navigator.userAgent.search("Chrome") >= 0) {
                      NAName="Chrome";
                    }
                    else if (navigator.userAgent.search("Firefox") >= 0) {
                      NAName="Firefox";
                    }
                    else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
                      NAName="Safari";
                    }
                    else if (navigator.userAgent.search("Opera") >= 0) {
                      NAName="Opera";
                    }
                // if (navigator.appVersion.indexOf("Android")!=-1) OSName="Android";
                
                const publicIp = require('public-ip');

                const url1=Config.baseurl+'log';
                const formData1 = new FormData();
                formData1.append('ip', await publicIp.v4());
                formData1.append('user', item.user[0].id_user);
                formData1.append('so', OSName);
                formData1.append('navegador', NAName);

                const response1 = await fetch(url1, {
                    method: 'POST',
                    body:formData1
                }).then(response =>response.json()).then(item=>{
                    
                });
                console.log("ddddddddddddddddd",item);
                toast.success("Bienvenido "+item.user[0].nombre, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });

            }else{
                toast.error("Error:usuario o contraseña no valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });

    } catch (error) {
        console.log(error)
    }
}

export const UpdateNota = (user,titulo,description,id) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'updatenota';
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('descripcion', description);
        formData.append('id', id);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        dispatch(DashboardCentral(user));
        toast.success("Nota modificada con exito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    } catch (error) {
        console.log(error)
    }
}
export const DeleteNota = (user,id) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'eliminarnota';
        const formData = new FormData();
        formData.append('id', id);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        dispatch(DashboardCentral(user));
        toast.success("Nota eliminada con exito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        
    } catch (error) {
        console.log(error)
    }
}

export const InsertMegusta = (user,id) => async (dispatch,getState) =>{
    try {        
        const url=Config.baseurl+'insertmegusta';
        const formData = new FormData();
        formData.append('user', user);
        formData.append('post', id);
        const response = await fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        dispatch(DashboardCentral(user));
        toast.success("Exito!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        
    } catch (error) {
        console.log(error)
    }
}

export const Purga = () => async (dispatch,getState) =>{
    try {        
        dispatch({
            type:PURGA
        });
    } catch (error) {
        console.log(error)
    }
}







export const InsertarCliente= (cliente)=> {
        const url=Config.baseurl+'InsertarCliente';
        const formData = new FormData();
        formData.append('nombre', cliente.nombre);
        formData.append('documento', cliente.documento);
        formData.append('tipo_doc', cliente.tipo_doc);
        formData.append('celular', cliente.celular);
        formData.append('fijo', cliente.fijo);
        formData.append('correo', cliente.correo);
        formData.append('correo2', cliente.correo2);
        formData.append('direccion1', cliente.direccion1);
        formData.append('direccion2', cliente.direccion2);
        formData.append('tipo', cliente.tipo);
        formData.append('categoria', cliente.categoria);
        formData.append('stamp', cliente.stamp);
        formData.append('pass', cliente.pass);
        formData.append('foto', cliente.foto);
        formData.append('ciudad', cliente.ciudad);
        formData.append('pais', cliente.pais);
        formData.append('empresa', cliente.empresa);
        formData.append('cargo', cliente.cargo);
        formData.append('campaign', cliente.campaign);
        formData.append('tags', cliente.tags);
        formData.append('autoriza', cliente.autoriza);
        formData.append('divisa', cliente.divisa);
        formData.append('estado', cliente.estado);
        formData.append('otro1', cliente.otro1);
        formData.append('otro2', cliente.otro2);
        formData.append('creador_id', cliente.usuario);
         fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        
           
            toast.success("Cliente Insertado Exitosamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
     
        
}

export const InsertarProducto= (producto, nomu)=> {
        const url=Config.baseurl+'InsertarProducto';
        const formData = new FormData();
        
        formData.append('titulo', producto.titulo);
        formData.append('codigo', producto.codigo);
        formData.append('descripcion_corta', producto.descripcion_corta);
        formData.append('descripcion_larga', producto.descripcion_larga);
        formData.append('precio', producto.precio);
        formData.append('foto', producto.foto);
        formData.append('cat1', producto.cat1);
        formData.append('cat2', producto.cat2);
        formData.append('cat3', producto.cat3);
        formData.append('agotado', producto.agotado);
        formData.append('tiempo', producto.tiempo);
        formData.append('estado', producto.estado);
        formData.append('publicado', producto.publicado);
        formData.append('exposicion', producto.exposicion);
        formData.append('promocion', producto.promocion);
        formData.append('nam', nomu);
         fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.text()).then(item=>console.log(item));
        
           
            toast.success("Producto Insertado Exitosamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
}






    /*export const BuscarCliente = (id) => async (dispatch,getState) =>{
        try {
            const url=Config.baseurl+'BuscarCliente/'+id;
            const response = await fetch(url).then(response=>response.json()).then(Json=>{
                dispatch({
                    type:STATE_DASHBOARD_CENTRAL,
                    payload: Json
                })
                dispatch(Json.id);
            });
            
        } catch (error) {
            console.log(error)
        }
    }*/


export const ActualizarCliente= (cliente) => {
    try{
        const url=Config.baseurl+'ActualizarCliente';
        const formData = new FormData();
        formData.append('id', cliente.id);
        formData.append('nombre', cliente.nombre);
        formData.append('documento', cliente.documento);
        formData.append('tipo_doc', cliente.tipo_doc);
        formData.append('celular', cliente.celular);
        formData.append('fijo', cliente.fijo);
        formData.append('correo', cliente.correo);
        formData.append('correo2', cliente.correo2);
        formData.append('direccion1', cliente.direccion1);
        formData.append('direccion2', cliente.direccion2);
        formData.append('tipo', cliente.tipo);
        formData.append('categoria', cliente.categoria);
        formData.append('stamp', cliente.stamp);
        formData.append('pass', cliente.pass);
        formData.append('foto', cliente.foto);
        formData.append('ciudad', cliente.ciudad);
        formData.append('pais', cliente.pais);
        formData.append('empresa', cliente.empresa);
        formData.append('cargo', cliente.cargo);
        formData.append('campaign', cliente.campaign);
        formData.append('tags', cliente.tags);
        formData.append('autoriza', cliente.autoriza);
        formData.append('divisa', cliente.divisa);
        formData.append('estado', cliente.estado);
        formData.append('otro1', cliente.otro1);
        formData.append('otro2', cliente.otro2);
         fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
           
            toast.success("Cliente Actualizado exitosamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        });
        
    } catch (error) {
        console.log(error)
    }
}

export const ActualizarProducto= (producto, nomu) => {
 
    try{
        const url=Config.baseurl+'ActualizarProducto';
        const formData = new FormData();
        formData.append('id', producto.id);
        formData.append('titulo', producto.titulo);
        formData.append('codigo', producto.codigo);
        formData.append('descripcion_corta', producto.descripcion_corta);
        formData.append('descripcion_larga', producto.descripcion_larga);
        formData.append('precio', producto.precio);
        formData.append('foto', producto.foto);
        formData.append('cat1', producto.cat1);
        formData.append('cat2', producto.cat2);
        formData.append('cat3', producto.cat3);
        formData.append('agotado', producto.agotado);
        formData.append('tiempo', producto.tiempo);
        formData.append('estado', producto.estado);
        formData.append('publicado', producto.publicado);
        formData.append('exposicion', producto.exposicion);
        formData.append('promocion', producto.promocion);
        formData.append('nam', nomu);
         fetch(url, {
            method: 'POST',
            body:formData
        }).then(response =>response.json()).then(Json=>{
           
            toast.success("Cliente Actualizado exitosamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        });
        
    } catch (error) {
        console.log(error)
    }
}



export const EliminarCliente= (clienteid) => {
    const url=Config.baseurl+'EliminarCliente';
    const formData = new FormData();
    formData.append('id', clienteid);
    
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success("Nota Borrada exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}

export const EliminarProducto= (productoid) => {
    const url=Config.baseurl+'EliminarProducto';
    const formData = new FormData();
    formData.append('id', productoid);
    
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success("Producto Borrado exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}

export const InsertarParametro= (parametro,tabla) => {

    const url=Config.baseurl+'InsertarParametro';
    const formData = new FormData();
    formData.append('parametro', parametro);
    formData.append('tabla', tabla);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Insertado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}

export const InsertarParametroProducto= (parametro,tabla) => {

    const url=Config.baseurl+'InsertarParametroProducto';
    const formData = new FormData();
    formData.append('parametro', parametro);
    formData.append('tabla', tabla);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Insertado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}


export const InsertarNotaCliente= (notaCliente,idCliente,Modulo,Imagen,usuario,nombrecreador) => {

    
  
    const url=Config.baseurl+'InsertarNotaCliente';
    const formData = new FormData();
    formData.append('nota', notaCliente);
    formData.append('idCliente', idCliente);
    formData.append('Modulo', Modulo);
    formData.append('Imagen', Imagen);
    
    formData.append('usuario', usuario);
    formData.append('nombrecreador', nombrecreador);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Nota Guardada Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
    

}



export const ActualizarParametro= (parametro,parametroItem) => {
  
    const url=Config.baseurl+'ActualizarParametro';
    const formData = new FormData();
    formData.append('parametro', parametro);
    formData.append('id', parametroItem);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Actualizado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}

export const ActualizarParametroProducto= (parametro,parametroItem) => {
  
    const url=Config.baseurl+'ActualizarParametroProducto';
    const formData = new FormData();
    formData.append('parametro', parametro);
    formData.append('id', parametroItem);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Actualizado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });
}


export const EliminarParametro=(idParametro)=>{

    const url=Config.baseurl+'EliminarParametro';
    const formData = new FormData();
    
    formData.append('id', idParametro);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Eliminado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });

}

export const EliminarParametroProducto=(idParametro)=>{

    const url=Config.baseurl+'EliminarParametroProducto';
    const formData = new FormData();
    
    formData.append('id', idParametro);
     fetch(url, {
        method: 'POST',
        body:formData
    }).then(response =>response.json()).then(Json=>{
       
        toast.success(" Parametro Eliminado Exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    });

}



