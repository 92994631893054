import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import DashboardReducer from './DashboardDusk';
import settings from '../redux/settings/reducer';
import menu from '../redux/menu/reducer';
import authUser from '../redux/auth/reducer';
import todoApp from '../redux/todo/reducer';
import chatApp from '../redux/chat/reducer';
import surveyListApp from '../redux/surveyList/reducer';
import surveyDetailApp from '../redux/surveyDetail/reducer';
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";


const rootReducer = combineReducers({
    Dash: DashboardReducer,
    menu,
    settings,
    authUser,
    todoApp,
    chatApp,
    surveyListApp,
    surveyDetailApp,
})

const persistConfig = {
  key: "root",
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore( persistedReducer, composeEnhancers( applyMiddleware(thunk) ) );
    const persistor = persistStore(store);
    return store
}


//------------------------------------------------------------------------



 

 

 
